import React from "react"
import tw from 'twin.macro';
import GlobalStyles from '../styles/GlobalStyles';
import Teaser from '../images/arcade_teaser_web.jpg';

const IndexPage = () => {

    const style = {
        backgroundImage: "url(" + Teaser + ")",
        backgroundPosition: "center -100px",
        backgroundRepeat: "no-repeat"
    }

    const paragraphStyle = {
        top: "550px",
        position: "relative"
    }

    return (
        <GlobalStyles>
            <div tw="bg-black h-screen" style={style}>
                <p style={paragraphStyle} tw="text-white text-white opacity-40 text-center uppercase tracking-widest font-bold">Coming Soon</p>
            </div>
        </GlobalStyles>
    )
}

export default IndexPage
